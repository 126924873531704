import whenReady from '../../../javascripts/utils/whenReady';

whenReady().then(() => {
  const $$image = document.querySelectorAll<HTMLImageElement>('.image__img');
  $$image.forEach(($image) => {
    if ($image.complete && $image.naturalHeight !== 0) {
      const $container = $image.closest('.image');
      $container?.classList.add('image--loaded');
    }
  });

  document.addEventListener(
    'load',
    (event) => {
      const { target: $image } = event;

      if (
        $image &&
        $image instanceof HTMLImageElement &&
        $image.matches('img.image__img')
      ) {
        const $container = $image.closest('.image');
        $container?.classList.add('image--loaded');
      }
    },
    {
      capture: true,
    },
  );
});
