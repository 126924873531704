import { on } from 'delegated-events';

on('click', 'a.js-popup', (event) => {
  event.preventDefault();

  const { currentTarget: $target } = event;
  const { name, title } = $target.dataset;

  window.open($target.href, name ?? title, 'width=600, height=600');
});
