import createSlider from '../../../javascripts/utils/createSlider';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

const isDesktop = window.matchMedia('(min-width: 768px)');
const $$stage = document.querySelectorAll<HTMLElement>('.stage');

$$stage.forEach(($stage) => {
  const $slides = $stage.querySelector<HTMLDivElement>('.stage__slides');

  if (!$slides || $slides.querySelectorAll('.stage__slide').length <= 1) {
    return;
  }

  const slider = createSlider($slides, '.stage__slide');

  $stage.addEventListener('pager:init', (event) => {
    const { pager } = event.detail;

    slider.onUpdate((currentSlide) => {
      pager.setCurrentPage(currentSlide, false);
    });

    if ($stage.hasAttribute('data-autoplay') && isDesktop.matches) {
      pager.start();
    }

    isDesktop.addEventListener('change', (mediaQueryEvent) => {
      if (!mediaQueryEvent.matches) {
        pager.stop();
      }
    });

    $stage.addEventListener('mouseenter', () => {
      pager.pause();
    });

    $stage.addEventListener('mouseleave', () => {
      pager.resume();
    });
  });

  $stage.addEventListener('pager:page', (event) => {
    const { currentPage, oldPage, manual } = event.detail;

    slider.moveTo(
      currentPage,
      currentPage < oldPage && manual ? 'prev' : 'next',
    );
  });

  $stage
    .querySelector<HTMLButtonElement>('.stage__navigation-button--prev')
    ?.addEventListener('click', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) invisibleFocus(currentTarget);
      slider.move('prev');
    });

  $stage
    .querySelector('.stage__navigation-button--next')
    ?.addEventListener('click', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) invisibleFocus(currentTarget);
      slider.move('next');
    });
});
