import { on } from 'delegated-events';

on('click', '.js-show-external-content', (event) => {
  event.preventDefault();

  // Get container
  const $externalContent =
    event.currentTarget.closest<HTMLDivElement>('.external-content');
  const $container = $externalContent?.querySelector<HTMLDivElement>(
    '.external-content__container',
  );

  if (!$container || !$externalContent) {
    return;
  }

  // Delete preview
  const $preview = $container.querySelector('.external-content__preview');
  $preview?.remove();

  // Unpack template with content
  const $content = $externalContent.querySelector<HTMLTemplateElement>(
    '.external-content__content',
  );
  if ($content) {
    $container.appendChild($content.content.cloneNode(true));
  }

  // Find iframe
  const $iframe = $container.querySelector<HTMLIFrameElement>('iframe');
  if ($iframe && $iframe.width && $iframe.height) {
    $iframe.classList.add('external-content__iframe');
    $container.style.paddingTop = `${
      (parseInt($iframe.height, 10) / parseInt($iframe.width, 10)) * 100
    }%`;
  }
});
