import createSlider from '../../../javascripts/utils/createSlider';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

const $$quoteSlider = document.querySelectorAll<HTMLElement>('.quote-slider');

$$quoteSlider.forEach(($quoteSlider) => {
  const $slides = $quoteSlider.querySelector<HTMLDivElement>(
    '.quote-slider__slides',
  );

  if (
    !$slides ||
    $slides.querySelectorAll('.quote-slider__slide').length <= 1
  ) {
    return;
  }

  const slider = createSlider($slides, '.quote-slider__slide');

  $quoteSlider.addEventListener('pager:init', (event) => {
    const { pager } = event.detail;

    slider.onUpdate((currentSlide) => {
      pager.setCurrentPage(currentSlide, false);
    });
  });

  $quoteSlider.addEventListener('pager:page', (event) => {
    const { currentPage, oldPage, manual } = event.detail;

    slider.moveTo(
      currentPage,
      currentPage < oldPage && manual ? 'prev' : 'next',
    );
  });

  $quoteSlider
    .querySelector<HTMLButtonElement>('.quote-slider__navigation-button--prev')
    ?.addEventListener('click', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) invisibleFocus(currentTarget);
      slider.move('prev');
    });

  $quoteSlider
    .querySelector('.quote-slider__navigation-button--next')
    ?.addEventListener('click', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) invisibleFocus(currentTarget);
      slider.move('next');
    });
});
