import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import moveFocus from '../../../javascripts/utils/moveFocus';
import elementIndex from '../../../javascripts/utils/elementIndex';

const switchTab = ($oldTab: HTMLAnchorElement, $newTab: HTMLAnchorElement) => {
  // Make the active tab focusable by the user (Tab key)
  $newTab.removeAttribute('tabindex');

  // Focus to new tab
  invisibleFocus($newTab);

  // Set the selected state
  $newTab.setAttribute('aria-selected', 'true');
  $oldTab.removeAttribute('aria-selected');
  $oldTab.setAttribute('tabindex', '-1');

  // Hide old panel
  const $oldPanel = document.getElementById($oldTab.hash.substr(1));

  $oldPanel?.classList.remove('tabs__panel--open');
  $oldPanel
    ?.querySelector('.tabs__panel-content')
    ?.setAttribute('aria-hidden', 'true');
  $oldPanel
    ?.querySelector('.tabs__panel-trigger')
    ?.setAttribute('aria-expanded', 'false');

  // Show old panel
  const $newPanel = document.getElementById($newTab.hash.substr(1));

  $newPanel?.classList.add('tabs__panel--open');
  $newPanel
    ?.querySelector('.tabs__panel-content')
    ?.setAttribute('aria-hidden', 'false');
  $newPanel
    ?.querySelector('.tabs__panel-trigger')
    ?.setAttribute('aria-expanded', 'true');
};

on('click', 'a.tabs__tab-link', (event) => {
  event.preventDefault();

  const { currentTarget: $tab } = event;
  const $currentTab = $tab
    .closest('.tabs__tabs')
    ?.querySelector<HTMLAnchorElement>('[aria-selected]');

  if ($currentTab && $tab !== $currentTab) {
    switchTab($currentTab, $tab);
  }
});

on('keydown', 'a.tabs__tab-link', (event) => {
  const { currentTarget: $tab } = event;

  if (!$tab.parentNode) {
    return;
  }

  // Move to open panel
  if (event.key === 'ArrowDown') {
    const $panel = document.getElementById($tab.hash.substr(1));
    const $content = $panel?.querySelector<HTMLDivElement>(
      '.tabs__panel-content',
    );

    if ($content) {
      moveFocus($content);
    }

    // Move between tabs
  } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
    const index = elementIndex($tab.parentNode as HTMLElement);
    const newIndex = event.key === 'ArrowLeft' ? index - 1 : index + 1;

    const $$tab = $tab
      .closest('.tabs__tabs')
      ?.querySelectorAll<HTMLAnchorElement>('.tabs__tab-link');

    if ($$tab && $$tab[newIndex]) {
      switchTab($tab, $$tab[newIndex]);
    }
  }
});

on('click', 'button.tabs__panel-trigger', (event) => {
  // Stop event here
  event.preventDefault();

  // Get state
  const { currentTarget: $trigger } = event;
  const $panel = $trigger.parentNode as HTMLElement;
  const $content = $panel?.querySelector<HTMLDivElement>(
    '.tabs__panel-content',
  );
  const tabId = $panel.getAttribute('aria-labelledby');
  const $tab = tabId
    ? (document.getElementById(tabId) as HTMLAnchorElement)
    : null;

  // Get current state
  const isOpen = $trigger.getAttribute('aria-expanded') === 'true';

  // Toggle state
  $trigger.setAttribute('aria-expanded', isOpen ? 'false' : 'true');
  $content?.setAttribute('aria-hidden', isOpen ? 'true' : 'false');

  // Actions on opening
  if (!isOpen) {
    // Change tab state
    $tab?.setAttribute('aria-selected', 'true');
    $tab?.removeAttribute('tabindex');

    // Close others
    $panel?.parentNode
      ?.querySelectorAll('.tabs__panel')
      .forEach(($siblingPanel) => {
        if ($siblingPanel !== $panel) {
          $siblingPanel
            .querySelector<HTMLButtonElement>('.tabs__panel-trigger')
            ?.setAttribute('aria-expanded', 'false');
          $siblingPanel
            .querySelector<HTMLButtonElement>('.tabs__panel-content')
            ?.setAttribute('aria-hidden', 'true');

          const siblingPanelId = $siblingPanel.getAttribute('aria-labelledby');
          if (!siblingPanelId) {
            return;
          }

          document
            .getElementById(siblingPanelId)
            ?.removeAttribute('aria-selected');
          document
            .getElementById(siblingPanelId)
            ?.setAttribute('tabindex', '-1');
        }
      });

    // Focus to next focusable element in content
    if ($content) {
      moveFocus($content);
    }
  }
});
