import fitty from 'fitty';
import createDesktop from './ribbon-slider.desktop';
import createMobile from './ribbon-slider.mobile';

document
  .querySelectorAll<HTMLDivElement>('.ribbon-slider')
  .forEach(($ribbonSlider) => {
    // Current deinit function
    let deinit: CallableFunction;

    // Init fitty
    const $numbers = $ribbonSlider.querySelectorAll<HTMLDivElement>(
      '.ribbon-slider__card-number',
    );

    const fitties = Array.from($numbers).map(($number) =>
      fitty($number, {
        minSize: 30,
        maxSize: 180,
        multiLine: true,
      }),
    );

    // Init media query
    const mql = window.matchMedia('(min-width: 1024px)');

    // Listener
    const onMediaQueryChange = (mq: MediaQueryListEvent | MediaQueryList) => {
      const isDesktop = mq.matches;

      deinit?.();

      if (isDesktop) {
        deinit = createDesktop($ribbonSlider);
      } else {
        deinit = createMobile($ribbonSlider);
      }

      fitties.forEach((f) => f.fit());
    };

    // Listen on media query changes
    mql.addEventListener('change', onMediaQueryChange);

    // First run
    onMediaQueryChange(mql);
    fitties.forEach((f) => f.fit());

    // Fit on resize
    window.addEventListener('resize', () => fitties.forEach((f) => f.fit()), {
      passive: true,
    });
  });
