module.exports = class PanoramaOverlay {
    constructor($el) {
        this.$el = $el;
        this.$closeButton = this.$el.querySelector('.panorama-overlay__window__closer');
        this.$prevButton = this.$el.querySelector('.panorama-overlay__prev-button') || false;
        this.$nextButton = this.$el.querySelector('.panorama-overlay__next-button') || false;
        this.$activeSheet = 1;
        this.$sheets = this.$el.querySelectorAll('.panorama-overlay__window__content__sheet');

        this.$scrollUpButton = this.$el.querySelector('.panorama-overlay__scroll-up-button');
        this.$scrollDownButton = this.$el.querySelector('.panorama-overlay__scroll-down-button');
        this.$scrollInterval = undefined;

        this.InitEvents();
        this.$el.classList.add('binded');
    }

    // Initiate Button Event
    InitEvents() {
        // close button
        this.$closeButton.addEventListener('click', (e) => {
            e.preventDefault();
            this.ClosePanoramaOverlay();
        });

        // close on background click
        this.$el.addEventListener('click', (e) => {
            if(e.target.classList.contains('panorama-overlay')) {
                this.ClosePanoramaOverlay();
            }
        });

        // sheet controls
        if(this.$prevButton) {
            this.$prevButton.addEventListener('click', (e) => {
                e.preventDefault();
                if(this.$activeSheet == 2) {
                    this.DisableButton(this.$prevButton, true);
                }
                this.DisableButton(this.$nextButton, false);

                this.$activeSheet--;
                this.SetActiveSheet();
                this.StopYtVideos();
                this.$el.querySelector('.panorama-overlay__window__content').scroll(0,0);
                this.DisableButton(this.$scrollUpButton, true);
                this.DisableButton(this.$scrollDownButton, false);
            });
            this.$nextButton.addEventListener('click', (e) => {
                e.preventDefault();
                if(this.$activeSheet + 1 >= this.$sheets.length) {
                    this.DisableButton(this.$nextButton, true);
                }
                this.DisableButton(this.$prevButton, false);

                this.$activeSheet++;
                this.SetActiveSheet();
                this.StopYtVideos();
                this.$el.querySelector('.panorama-overlay__window__content').scroll(0,0);
                this.DisableButton(this.$scrollUpButton, true);
                this.DisableButton(this.$scrollDownButton, false);
            });
        }

        // youtube video play buttons
        this.$el.querySelectorAll('.panorama-overlay__yt-video__loader-image button').forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                let poytElem = e.target.closest('.panorama-overlay__yt-video');
                poytElem.classList.add('play');
                poytElem.appendChild(document.importNode(poytElem.querySelector('template').content,true));
            })
        });

        // scroll up/down buttons
        this.$scrollUpButton.addEventListener('click', e => {
            e.preventDefault();
            this.$el.querySelector('.panorama-overlay__window__content').scroll({
                behavior: 'smooth',
                left: 0,
                top: this.$el.querySelector('.panorama-overlay__window__content').scrollTop - window.innerHeight/3,
            });
            this.$scrollInterval = window.setInterval(()=>{
                window.clearInterval(this.$scrollInterval);
                this.DisableButton(this.$scrollDownButton, false);
                if(this.$el.querySelector('.panorama-overlay__window__content').scrollTop <= 0) {
                    this.DisableButton(this.$scrollUpButton, true);
                }
            }, 500);
        });
        this.$scrollDownButton.addEventListener('click', e => {
            e.preventDefault();
            this.$el.querySelector('.panorama-overlay__window__content').scroll({
                behavior: 'smooth',
                left: 0,
                top: this.$el.querySelector('.panorama-overlay__window__content').scrollTop + window.innerHeight/3,
            });
            this.$scrollInterval = window.setInterval(()=>{
                window.clearInterval(this.$scrollInterval);
                this.DisableButton(this.$scrollUpButton, false);
                if(this.$el.querySelector('.panorama-overlay__window__content').scrollTop >= this.$el.querySelector('.panorama-overlay__window__content').scrollHeight - this.$el.querySelector('.panorama-overlay__window__content').clientHeight) {
                    this.DisableButton(this.$scrollDownButton, true);
                }
            }, 500);
        });

    }

    // Set active Sheet
    SetActiveSheet() {
        this.$el.querySelector('.panorama-overlay__window__content__sheet.active').classList.remove('active');
        this.$sheets[this.$activeSheet-1].classList.add('active');
    }

    // Stop YouTube Playing Videos
    StopYtVideos() {
        this.$el.querySelectorAll('.panorama-overlay__yt-video.play').forEach((poytElem) => {
            poytElem.querySelector('iframe').remove();
            poytElem.classList.remove('play');
        });
    }

    // Dis-/Enable Button
    DisableButton(button, disabled) {
        button.disabled = disabled;
        button.setAttribute('aria-disabled', disabled);
    }

    // Close Panorama Overlay
    ClosePanoramaOverlay() {
        if(typeof this.$el !== "undefined") {
            let el = this.$el;
            this.$el.closest('.panorama-viewer').scrollIntoView();
            this.$el.remove();
            this.$el = undefined;
            el.dispatchEvent(new CustomEvent('overlay-closed', { bubbles: false }));
        }
    }
}

document.querySelectorAll('.panorama-overlay:not(.binded)').forEach(el => new PanoramaOverlay(el));