import MicroModal from 'micromodal';
import { on } from 'delegated-events';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import whenReady from '../../../javascripts/utils/whenReady';

const enableModal = ($modal: Element): void => {
  const $content = $modal.querySelector('.modal__content');

  if (!$content) {
    return;
  }

  MicroModal.show($modal.id, {
    onShow() {
      disableBodyScroll($content);
    },
    onClose() {
      enableBodyScroll($content);

      if ($modal.hasAttribute('data-destory-on-close')) {
        $modal.parentNode?.removeChild($modal);
      }
    },
  });
};

whenReady().then(() => {
  const $openModal = document.querySelector('.modal[aria-hidden="false"]');

  if ($openModal) {
    enableModal($openModal);
  }
});

on('click', 'a.js-modal', (event) => {
  const { href: target } = event.currentTarget;
  const $modal = document.querySelector<HTMLElement>(target);

  if ($modal) {
    event.preventDefault();
    enableModal($modal);
  }
});

on('click', '.modal__close, .modal__background', (event) => {
  const $target = event.currentTarget.closest('.modal');

  if ($target) {
    MicroModal.close($target.id);
  }
});

on('submit', '.modal form', (event) => {
  const $target = event.currentTarget.closest('.modal');

  if ($target) {
    MicroModal.close($target.id);
  }
});

document.body.addEventListener('htmx:afterSwap', () => {
  const $openModal = document.querySelector('.modal[aria-hidden="false"]');

  if ($openModal) {
    enableModal($openModal);
  }
});

export default enableModal;
