import { PagerNewCurrentPageEvent } from '../../1-atoms/pager/pager';

const create = ($ribbonSlider: HTMLDivElement): CallableFunction => {
  const $cards = $ribbonSlider.querySelector<HTMLDivElement>(
    '.ribbon-slider__cards',
  );

  if (!$cards) {
    throw new Error('Cards are missing!');
  }

  const onPage = (event: PagerNewCurrentPageEvent) => {
    const { currentPage } = event.detail;

    $cards.style.transform = `translateX(-${currentPage * 100}%)`;
  };

  $ribbonSlider.addEventListener('pager:page', onPage);

  return () => {
    $ribbonSlider.removeEventListener('pager:page', onPage);
  };
};

export default create;
