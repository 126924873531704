export type TimerState = boolean;

export type ProgressCallback = (progress: number) => void;

export interface Timer {
  start(): void;
  stop(): void;
  pause(): TimerState;
  resume(): TimerState;
  paused(): TimerState;
  stopped(): TimerState;
  onTick(callback: ProgressCallback): void;
}

const createTimer = (
  callback: CallableFunction,
  duration: number,
  repeat = false,
): Timer => {
  let nextFrameId: number;
  let startTime: number;
  let remainingTime = 0;
  let isPaused = true;
  let isStopped = true;
  let onTickCallback: ProgressCallback;

  const poll = (pollingTimestamp: number) => {
    if (isPaused || isStopped) {
      return;
    }

    remainingTime = duration - (pollingTimestamp - startTime);

    if (remainingTime <= 0) {
      nextFrameId = 0;
      remainingTime = 0;
      callback();

      if (repeat) {
        remainingTime = duration;
        startTime = performance.now();
      }
    }

    if (remainingTime > 0) {
      onTickCallback?.(1 - remainingTime / duration);
      nextFrameId = requestAnimationFrame(poll);
    }
  };

  const start = () => {
    if (nextFrameId > 0) {
      return;
    }

    remainingTime = duration;
    isPaused = false;
    isStopped = false;
    startTime = performance.now();
    poll(startTime);
  };

  const pause = () => {
    isPaused = true;
    cancelAnimationFrame(nextFrameId);

    return isPaused;
  };

  const resume = () => {
    if (isPaused) {
      isPaused = false;
      startTime = performance.now() - (duration - remainingTime);
      poll(startTime);
    }

    return isPaused;
  };

  const paused = () => isPaused;

  const stopped = () => isStopped;

  const stop = () => {
    if (nextFrameId > 0) {
      isPaused = true;
      isStopped = true;
      cancelAnimationFrame(nextFrameId);
      remainingTime = 0;
      nextFrameId = 0;
    }
  };

  const onTick = (progressCallback: ProgressCallback) => {
    onTickCallback = progressCallback;
  };

  return { start, pause, resume, stop, paused, stopped, onTick };
};

export default createTimer;
