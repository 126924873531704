import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import moveFocus from '../../../javascripts/utils/moveFocus';

const createMobileNavigation = (
  $navigation: HTMLDivElement,
): CallableFunction | undefined => {
  const allActiveFocusTraps = new Set<FocusTrap>();

  const $header = $navigation.closest<HTMLDivElement>('.header');

  const $navigationOverlay = $header?.querySelector<HTMLDivElement>(
    '.header__navigation',
  );

  const $menuButton = $header?.querySelector<HTMLButtonElement>(
    '.header__menu-button',
  );

  const $closeButton = $header?.querySelector<HTMLButtonElement>(
    '.header__navigation-close',
  );

  if (!$navigationOverlay || !$menuButton) {
    return undefined;
  }

  // On menu open
  const onMenuOpenClick = () => {
    let focusTrap: FocusTrap;

    // Close button
    const onCloseButtonClick = (closeButtonEvent: MouseEvent) => {
      closeButtonEvent.preventDefault();
      focusTrap?.deactivate();
    };

    // Show navigation menu
    $navigationOverlay.classList.add('header__navigation--open');

    // Create a focus trap
    focusTrap = createFocusTrap($navigationOverlay, {
      escapeDeactivates: true,
      allowOutsideClick: false,
      initialFocus: false,
      returnFocusOnDeactivate: false,
      onActivate() {
        // Enable close button
        $closeButton?.addEventListener('click', onCloseButtonClick);

        // Move focus to first navigation item
        moveFocus($navigation);

        // Prevent body scroll
        disableBodyScroll($navigation);

        // Add to focus trap store
        allActiveFocusTraps.add(focusTrap);
      },
      onDeactivate() {
        // Hide navigation menu
        $navigationOverlay.classList.remove('header__navigation--open');

        // Re-enable body scroll
        enableBodyScroll($navigation);

        // Disable close button
        $closeButton?.removeEventListener('click', onCloseButtonClick);

        // Remove from focus trap store
        allActiveFocusTraps.delete(focusTrap);
      },
    });

    // Enable focusTrap
    focusTrap.activate();
  };

  // On link click
  const onMoveClick = (event: MouseEvent) => {
    let focusTrap: FocusTrap;

    const $trigger =
      event.target instanceof Element
        ? event.target.closest<HTMLLinkElement>(
            '.header__navigation-1st-level-link, .header__navigation-2nd-level-link',
          )
        : null;

    const $submenu =
      $trigger?.parentNode?.querySelector<HTMLUListElement>('ul');

    if (!$trigger || !$submenu) {
      return;
    }

    // Prevent default link handler
    event.preventDefault();

    // Make new submenu visible
    $submenu.hidden = false;

    // Move navigation to the next level
    if ($navigation.dataset.level === '2') {
      $navigation.dataset.level = '3';
    } else {
      $navigation.dataset.level = '2';
    }

    // Enable back button
    const $backButton = $submenu.querySelector<HTMLButtonElement>(
      '.header__navigation-2nd-level-back, .header__navigation-3rd-level-back',
    );

    const onBackButtonClick = (backButtonEvent: MouseEvent) => {
      backButtonEvent.preventDefault();
      focusTrap?.deactivate();
    };

    // Create focus trap
    focusTrap = createFocusTrap($submenu, {
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      allowOutsideClick: true,
      initialFocus: false,
      returnFocusOnDeactivate: false,
      onActivate() {
        // Set aria attribute
        $trigger.setAttribute('aria-expanded', 'true');

        // Add back button event
        $backButton?.addEventListener('click', onBackButtonClick);

        // Focus on first link
        const $firstLink = $submenu.querySelector<HTMLAnchorElement>(
          '.header__navigation-2nd-level-link, .header__navigation-3rd-level-link',
        );

        if ($firstLink) {
          invisibleFocus($firstLink);
        }

        // Add to focus trap store
        allActiveFocusTraps.add(focusTrap);
      },
      onDeactivate() {
        // Set aria attribute
        $trigger.setAttribute('aria-expanded', 'false');

        // Remove back button event
        $backButton?.removeEventListener('click', onBackButtonClick);

        // Hides menu after navigation has slide back
        const onTransitionEnd = () => {
          $submenu.hidden = true;
          $navigation.removeEventListener('transitionend', onTransitionEnd);
        };

        $navigation.addEventListener('transitionend', onTransitionEnd);

        // Move navigation back to the previous level
        if ($navigation.dataset.level === '3') {
          $navigation.dataset.level = '2';
        } else {
          $navigation.dataset.level = '1';
        }

        // Move focus back to the trigger
        invisibleFocus($trigger);

        // Remove from focus trap store
        allActiveFocusTraps.delete(focusTrap);
      },
    });

    // Enables focus trap after the navigation as slides to the next level
    const onTransitionEnd = () => {
      focusTrap?.activate();
      $navigation.removeEventListener('transitionend', onTransitionEnd);
    };

    $navigation.addEventListener('transitionend', onTransitionEnd);
  };

  // Modify DOM
  $navigation
    .querySelectorAll<HTMLUListElement>(
      '.header__navigation-2nd-level, .header__navigation-3rd-level',
    )
    .forEach(($submenu) => {
      // Add aria-expanded & aria-controls
      const $trigger =
        $submenu?.parentElement?.querySelector<HTMLAnchorElement>(
          '.header__navigation-1st-level-link, .header__navigation-2nd-level-link',
        );

      $trigger?.setAttribute('aria-expanded', 'false');
      $trigger?.setAttribute('aria-controls', $submenu.id);
    });

  // Init events
  $navigation.addEventListener('click', onMoveClick);
  $menuButton.addEventListener('click', onMenuOpenClick);

  // Deinit function
  return () => {
    // Kill still active focus traps
    allActiveFocusTraps.forEach((focusTrap) => focusTrap.deactivate());

    // Deinit events
    $navigation.removeEventListener('click', onMoveClick);

    // Reshow all submenus
    $navigation
      .querySelectorAll<HTMLUListElement>(
        '.header__navigation-2nd-level, .header__navigation-3rd-level',
      )
      .forEach(($submenu) => {
        // Remove aria-expanded & aria-controls
        const $trigger =
          $submenu?.parentElement?.querySelector<HTMLAnchorElement>(
            '.header__navigation-1st-level-link, .header__navigation-2nd-level-link',
          );

        $trigger?.removeAttribute('aria-expanded');
        $trigger?.removeAttribute('aria-controls');

        // Hide all submenus
        $submenu.hidden = true;
      });
  };
};

export default createMobileNavigation;
