const BABYLON = require('babylonjs');
const GUI = require ('babylonjs-gui');

var BindNewWorks, NewWork;

NewWork = class NewWork {
  constructor(el) {
    this.$el = el;
    this.$canvas = void 0;
    this.$loadingScreen = void 0;
    this.$userHints = void 0;
    this.assets = {
      loadChecker: void 0,
      loadCheckerInterval: 200,
      manager: void 0,
      meshTasks: [],
      openTasks: 0,
      rootUrl: "3d-assets/"
    };
    this.camera = {
      camera: void 0,
      pinchPrecision: 30,
      radius: {
        far: 50,
        near: 6
      },
      startPosition: new BABYLON.Vector3(-10, 9, -15),
      targetNulled: true
    };
    this.config = void 0;
    this.debug = true;
    this.dpr = devicePixelRatio ? devicePixelRatio : 1;
    this.engine = void 0;
    this.fxaa = false;
    this.gui = {
      gui: void 0,
      renderScale: 1
    };
    this.hsl = void 0;
    this.id = this.$el.id;
    this.infopoints = {
      cameraZoomShiftLevel: 20,
      closeByCanvasClick: true,
      data: [],
      elements: [],
      overlayDefaultOffset: 200,
      sprite: {
        file: "elem_plus-symbol.png",
        height: 600,
        manager: void 0,
        size: {
          default: .75,
          hover: .85
        },
        width: 600
      },
      textElements: [],
      textElementsDefaultOffset: 110,
      trackSpheres: [],
      trackSpheresMaterial: void 0
    };
    this.infoPointClick = false;
    this.lights = [];
    this.meshes = [];
    this.overlayOpen = false;
    this.postProcesses = [];
    this.resizeFactor = 1;
    this.scene = {
      optimized: false,
      optimizer: void 0,
      scene: void 0
    };
    this.shadows = false;
    this.showDebugLayer = false;
    this.shadowGens = [];
    this.targetedFps = 45;
    this.touchDetected = false;
    this.userHints = {
      animated: true,
      animation: void 0,
      animationTransitionTime: 5000,
      show: true
    };
    this.waypoints = {
      cameraLift: 3,
      data: [],
      elements: [],
      material: void 0,
      texture: {
        animated: true,
        animation: void 0,
        animationStep: 0.05,
        animationTime: 100,
        file: "tex_waypoint.png",
        texture: void 0
      },
      transitionTime: 1000,
      triggerDelay: void 0
    };
    this.windowResizeDelay = 100;
    this.windowResizeInterval = void 0;
    this.$el.classList.add('new-work--binded');
    this.Build();
  }

  AddLight(type, name, position, direction, spotLightConeAngle, spotLightExponent) {
    if (type === "direct") {
      this.lights[name] = new BABYLON.DirectionalLight(name, new BABYLON.Vector3(position[0], position[1], position[2]), this.scene.scene);
      if (direction) {
        this.lights[name].direction = new BABYLON.Vector3(direction[0], direction[1], direction[2]);
      }
    }
    if (type === "hemi") {
      this.lights[name] = new BABYLON.HemisphericLight(name, new BABYLON.Vector3(position[0], position[1], position[2]), this.scene.scene);
    }
    if (type === "point") {
      this.lights[name] = new BABYLON.PointLight(name, new BABYLON.Vector3(position[0], position[1], position[2]), this.scene.scene);
    }
    if (type === "spot" && direction && spotLightConeAngle && spotLightExponent) {
      this.lights[name] = new BABYLON.SpotLight(name, new BABYLON.Vector3(position[0], position[1], position[2]), new BABYLON.Vector3(direction[0], direction[1], direction[2]), spotLightConeAngle, spotLightExponent, this.scene.scene);
    }
    return this.lights[name];
  }

  AddMeshTask(name, fileName, shadowGen) {
    var self;
    self = this;
    // create new mesh task
    this.assets.meshTasks[name] = this.assets.manager.addMeshTask(name, "", this.assets.rootUrl, fileName);
    // increase openTasks count
    this.assets.openTasks++;
    // success function
    this.assets.meshTasks[name].onSuccess = function(task) {
      var i, len, mesh, ref;
      ref = task.loadedMeshes;
      for (i = 0, len = ref.length; i < len; i++) {
        mesh = ref[i];
        if (shadowGen) {
          shadowGen.addShadowCaster(mesh);
          mesh.receiveShadows = true;
        }
        self.meshes[mesh.id] = mesh;
      }
      self.assets.openTasks--;
    };
    // error function
    this.assets.meshTasks[name].onError = function(task, message, exception) {
      if (self.debug) {
        console.log("MeshTask loading error: ", message, exception);
      }
      this.assets.openTasks--;
    };
  }

  AddPostProcess(name, postProcess) {
    this.postProcesses[name] = postProcess;
  }

  Build() {
    if (this.$el) {
      this.GetConfig();
      this.CreateCanvas();
      this.CreateLoadingScreen();
      this.CreateScene();
      this.RunScene();
      this.InitEvents();
      this.WindowResize();
      this.CreateUserHints();
    }
  }

  CreateCanvas() {
    this.$canvas = document.createElement('canvas');
    this.$canvas.classList.add('new-work__canvas');
    this.$canvas.height = window.innerHeight;
    this.$canvas.width = window.innerWidth;
    this.$el.appendChild(this.$canvas);
  }

  CreateInfopoints() {
    var count, ctx, i, infopoint, infopointData, len, ref, self, textElement, textMetrics, textOffset, trackSphere;
    self = this;
    if (this.debug) {
      console.log("CREATE INFOPOINTS");
    }
    // load global sprite texture
    this.infopoints.sprite.manager = new BABYLON.SpriteManager("infopointSpriteManager", this.assets.rootUrl + this.infopoints.sprite.file, this.infopoints.sprite.width, this.infopoints.sprite.height, this.scene.scene);
    // set sprite manager on higher render group to always render above other 3D content
    this.infopoints.sprite.manager.renderingGroupId = 1;
    // make sprite manager pickable for actions
    this.infopoints.sprite.manager.isPickable = true;
    // create global track spheres material
    this.infopoints.trackSpheresMaterial = new BABYLON.StandardMaterial("trackspheres", this.scene.scene);
    this.infopoints.trackSpheresMaterial.alpha = 0;
    // build individual infopoints
    count = 0;
    ref = this.infopoints.data;
    for (i = 0, len = ref.length; i < len; i++) {
      infopointData = ref[i];
      if (this.debug) {
        console.log("--- CREATE INFOPOINT", infopointData);
      }
      // create sprite from global sprite texture
      infopoint = new BABYLON.Sprite("infopoint_" + infopointData.name, this.infopoints.sprite.manager);
      // set position and size
      infopoint.position = new BABYLON.Vector3(infopointData.position.x, infopointData.position.y, infopointData.position.z);
      infopoint.size = this.infopoints.sprite.size.default;
      infopoint.isVisible = false;
      // create track sphere as mesh for overlay positioning
      trackSphere = BABYLON.MeshBuilder.CreateSphere("infopoint_track-sphere_" + infopointData.name, {}, this.scene.scene);
      trackSphere.position = new BABYLON.Vector3(infopointData.position.x, infopointData.position.y, infopointData.position.z);
      trackSphere.isPickable = false;
      trackSphere.material = this.infopoints.trackSpheresMaterial;
      this.infopoints.trackSpheres["infopoint_" + infopointData.name] = trackSphere;
      // create text elements for infopoints#
      textElement = new GUI.Button.CreateSimpleButton(infopointData.name, infopointData.text);
      textElement.background = 'rgba(0,0,0,0)';
      textElement.color = "#085AE2";
      textElement.textWrapping = true;
      textElement.resizeToFit = true;
      textElement.height = (50 * this.gui.renderScale * this.dpr) + "px";
      textElement.fontFamily = "ATRUVIA,Helvetica,Arial,sans-serif";
      textElement.fontWeight = 500;
      textElement.fontSize = 36 * this.gui.renderScale * this.dpr;
      textElement.thickness = 0;
      textElement.pickable = true;
      ctx = this.gui.gui.getContext();
      ctx.font = textElement.fontSizeInPixels + "px " + textElement.fontFamily;
      textMetrics = ctx.measureText(textElement.text);
      textElement.width = (textMetrics.width + (40 * this.gui.renderScale * this.dpr)) + "px";
      this.gui.gui.addControl(textElement);
      textElement.linkWithMesh(this.infopoints.trackSpheres["infopoint_" + infopointData.name]);
      textOffset = infopointData.textOffset ? infopointData.textOffset : 0;
      textElement.linkOffsetX = ((this.infopoints.textElementsDefaultOffset + textOffset) - (1 * this.camera.camera.radius)) * infopointData.textAlignment * this.gui.renderScale * this.dpr;
      textElement.isVisible = false;
      textElement.onPointerUpObservable.add(function(e, eventState) {
        self.infopointClick = true;
        self.ShowInfopointOverlay(self.infopoints.data.indexOf(self.infopoints.data.find(function(element) {
          return element.name === eventState.target.name;
        })));
      });
      this.infopoints.textElements["infopoint_" + infopointData.name] = textElement;
      // make pickable for actions
      infopoint.isPickable = true;
      infopoint.actionManager = new BABYLON.ActionManager(this.scene.scene);
      // bind click action   
      infopoint.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickUpTrigger, function(e) {
        self.infopointClick = true;
        self.ShowInfopointOverlay(self.infopoints.data.indexOf(self.infopoints.data.find(function(element) {
          return element.name === e.source.name.substring(10, e.source.name.length);
        })));
      }, null));
      // bind hover actions
      infopoint.actionManager.registerAction(new BABYLON.InterpolateValueAction(BABYLON.ActionManager.OnPointerOutTrigger, infopoint, "size", this.infopoints.sprite.size.default, 150));
      infopoint.actionManager.registerAction(new BABYLON.InterpolateValueAction(BABYLON.ActionManager.OnPointerOverTrigger, infopoint, "size", this.infopoints.sprite.size.hover, 150));
      // add to global infopoints elements list
      this.infopoints.elements["infopoint_" + infopointData.name] = infopoint;
      count++;
    }
    // close infopoint overlays by canvas click if options is set
    if (this.infopoints.closeByCanvasClick) {
      this.$canvas.addEventListener('click', function(e) {
        if (!self.infopointClick) {
          self.RemoveInfopointOverlay();
        } else {
          self.infopointClick = false;
        }
      });
      this.$canvas.addEventListener('touchend', function(e) {
        if (!self.infopointClick) {
          self.RemoveInfopointOverlay();
        } else {
          self.infopointClick = false;
        }
      });
    }
  }

  CreateLoadingScreen() {
    var self;
    self = this;
    if (!this.$loadingScreen) {
      this.$loadingScreen = document.createElement('div');
      this.$loadingScreen.classList.add('new-work__loading-screen');
      this.$el.appendChild(this.$loadingScreen);
    }
    BABYLON.DefaultLoadingScreen.prototype.displayLoadingUI = function() {
      if (self.$loadingScreen) {
        self.$loadingScreen.style.display = "initial";
        return;
      }
      this._loadingDiv = document.createElement('div');
      this._loadingDiv.id = self.id + '__loading-screen';
      //@._loadingDiv.classList.add 'campushero-loading-screen'
      this._loadingDiv.innerHTML = '<div class="new-work__loading-screen__spinner"></div><div class="new-work__loading-screen__text">Daten werden geladen</div>';
      this._resizeLoadingUI();
      window.addEventListener('resize', this._resizeLoadingUI);
      document.body.appendChild(this._loadingDiv);
    };
    BABYLON.DefaultLoadingScreen.prototype.hideLoadingUI = function() {
      self.$loadingScreen.classList.add('new-work__loading-screen--hidden');
      if (self.debug) {
        console.log("SCENE LOADED - LOADING SCREEN HIDDEN");
      }
    };
  }

  CreateScene() {
    var dLight, dLight2, dLightShadowGen, sLight;
    if (this.debug) {
      console.log("CONFIG", this.config);
    }
    if (this.$canvas && this.config) {
      this.engine = new BABYLON.Engine(this.$canvas, true);
      this.engine.setHardwareScalingLevel(1 / window.devicePixelRatio);
      this.hsl = this.engine.getHardwareScalingLevel();
      this.scene.scene = new BABYLON.Scene(this.engine);
      this.scene.scene.clearColor = new BABYLON.Color4(0, 0, 0, 0); // transparent background
      if (this.showDebugLayer) {
        this.scene.scene.debugLayer.show();
      }
      this.scene.scene.shadowsEnabled = this.shadows;
      this.engine.displayLoadingUI();
      // ++++ Camera ++++
      this.camera.camera = new BABYLON.ArcRotateCamera("camera", -Math.PI / 2, Math.PI / 2.5, 3, this.camera.startPosition, this.scene.scene);
      this.camera.camera.attachControl(this.$canvas, true);
      // disable free movement
      this.camera.camera.inputs.removeByType("ArcRotateCameraKeyboardMoveInput");
      this.camera.camera.inputs.removeByType("ArcRotateCameraMouseMoveInput");
      // limit zoom
      this.camera.camera.upperRadiusLimit = this.camera.radius.far;
      this.camera.camera.lowerRadiusLimit = this.camera.radius.near;
      // limit rotation
      this.camera.camera.lowerBetaLimit = 0;
      this.camera.camera.upperBetaLimit = Math.PI * (70 / 180);
      // set pinch precision for mobile zooming
      this.camera.camera.pinchPrecision = this.camera.pinchPrecision;
      this.camera.camera.useFramingBehaviour = true;
      this.camera.camera.setTarget(BABYLON.Vector3.Zero());
      if (!this.gui.gui) {
        if (this.debug) {
          console.log("CREATE GUI");
        }
        this.gui.gui = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI", void 0, void 0, BABYLON.Texture.LINEAR_LINEAR);
        this.gui.gui.renderScale = this.gui.renderScale;
      }
      
      // ++++ Lights ++++
      dLight = this.AddLight("direct", "#dLight-01", [12, -10, 18], [1, -1, 1]);
      dLight.intensity = 3;
      dLightShadowGen = this.CreateShadowGenerator(dLight, 1024, 10, 100, true, false);
      dLight2 = this.AddLight("direct", "#dLight-02", [0, -1, 0], [-1, -1, -1]);
      dLight2.intensity = 1;
      sLight = this.AddLight("spot", "#sLight-01", [-8.32, 2.93, 1.06], [.64, -.23, .73], 10, 24);
      sLight.intensity = 50;
      sLight.diffuse = new BABYLON.Color3(1, .9, .8);
      // +++ Assets Manager ++++
      this.assets.manager = new BABYLON.AssetsManager(this.scene.scene);
      // disable default loading screen
      this.assets.manager.useDefaultLoadingScreen = false;
      // load mesh assets
      this.AddMeshTask("campusmodel", "campushero_220315.babylon", dLightShadowGen);
      this.AddMeshTask("campusmodel_highchair", "campushero_220315_HighChair.babylon", dLightShadowGen);
      this.AddMeshTask("campusmodel_kitchenseat", "campushero_220315_KitchenSeat.babylon", dLightShadowGen);
      this.AddMeshTask("campusmodel_deskgroup", "campushero_220315_DeskGroup.babylon", dLightShadowGen);
      this.AddMeshTask("campusmodel_kitchentable", "campushero_220315_KitchenTable.babylon", dLightShadowGen);
      this.LoadAssets();
      // ++++ Infopoints ++++
      this.CreateInfopoints();
      // ++++ Post Processes ++++
      if (this.fxaa) {
        this.AddPostProcess("fxaa", new BABYLON.FxaaPostProcess("fxaa", 1.0, this.camera.camera));
      }
      
      // ++++ Waypoints ++++
      this.CreateWaypoints();
      // ++++ Scene Optimizer ++++
      this.CreateSceneOptimizer();
    }
    return this.scene;
  }

  CreateSceneOptimizer() {
    var options, self;
    if (!this.scene.optimizer) {
      options = new BABYLON.SceneOptimizerOptions(this.targetedFps, 500);
      options.addOptimization(new BABYLON.ShadowsOptimization(0));
      options.addOptimization(new BABYLON.TextureOptimization(1, 1024));
      options.addOptimization(new BABYLON.HardwareScalingOptimization(2, 1));
      this.scene.optimizer = new BABYLON.SceneOptimizer(this.scene.scene, options);
      self = this;
      this.scene.optimizer.onSuccessObservable.add(function() {
        if (self.debug) {
          console.log("OPTIMIZATION WORKED");
        }
        self.PostSceneOptimizationProcesses();
      });
      this.scene.optimizer.onFailureObservable.add(function() {
        if (self.debug) {
          console.log("OPTIMIZATION FAILED");
        }
        self.PostSceneOptimizationProcesses();
      });
    }
    this.scene.optimizer.start();
  }

  CreateShadowGenerator(light, size, min, max, enableBlur, enableContactHard) {
    var shadowGen;
    shadowGen = new BABYLON.ShadowGenerator(size, light);
    if (enableBlur) {
      shadowGen.useBlurCloseExponentialShadowMap = true;
    }
    if (enableContactHard) {
      shadowGen.useContactHardeningShadow = true;
    }
    if (min !== void 0 && max !== void 0) {
      light.shadowMinZ = min;
      light.shadowMaxZ = max;
    }
    this.shadowGens[light.name] = shadowGen;
    return this.shadowGens[light.name];
  }

  CreateUserHints() {
    var self;
    if (this.userHints.show) {
      self = this;
      this.$userHints = document.createElement('div');
      this.$userHints.classList.add('new-work__userhints');
      this.$userHints.innerHTML = '<div class="new-work__userhints__icon new-work__userhints__icon--rotation"></div> <div class="new-work__userhints__icon new-work__userhints__icon--zoom"></div>';
      this.$el.appendChild(this.$userHints);
      this.$userHints.querySelector('.new-work__userhints__icon').classList.add('new-work__userhints__icon--active');
      this.userHints.animation = window.setInterval(function() {
        var activeElem, nextElem;
        activeElem = self.$userHints.querySelector('.new-work__userhints__icon--active');
        nextElem = activeElem.nextElementSibling;
        activeElem.classList.remove('new-work__userhints__icon--active');
        if (nextElem) {
          nextElem.classList.add('new-work__userhints__icon--active');
        } else {
          self.$userHints.querySelectorAll('.new-work__userhints__icon')[0].classList.add('new-work__userhints__icon--active');
        }
      }, this.userHints.animationTransitionTime);
    }
  }

  CreateWaypoints() {
    var i, len, ref, self, waypoint, waypointData;
    self = this;
    // make global material
    this.waypoints.material = new BABYLON.StandardMaterial("waypoint", this.scene.scene);
    this.waypoints.texture.texture = new BABYLON.Texture(this.assets.rootUrl + this.waypoints.texture.file, this.scene.scene);
    this.waypoints.material.diffuseTexture = this.waypoints.texture.texture;
    this.waypoints.material.diffuseTexture.hasAlpha = true;
    this.waypoints.material.emissiveTexture = this.waypoints.texture.texture;
    this.waypoints.material.opacityTexture = this.waypoints.texture.texture;
    this.waypoints.material.alpha = .5;
    // create global animation if set
    if (this.waypoints.texture.animated) {
      this.waypoints.texture.animation = window.setInterval(function() {
        if (self.waypoints.material.alpha >= 1 || self.waypoints.material.alpha < .5) {
          self.waypoints.texture.animationStep *= -1;
        }
        self.waypoints.material.alpha += self.waypoints.texture.animationStep;
      }, this.waypoints.texture.animationTime);
    }
    ref = this.waypoints.data;
    // build individual waypoints
    for (i = 0, len = ref.length; i < len; i++) {
      waypointData = ref[i];
      // build mesh
      waypoint = BABYLON.MeshBuilder.CreatePlane("waypoint_" + waypointData.name, {
        size: 2
      }, this.scene.scene);
      // assign global material
      waypoint.material = this.waypoints.material;
      // set position
      waypoint.position = new BABYLON.Vector3(waypointData.position.x, waypointData.position.y, waypointData.position.z);
      waypoint.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
      // bind click action
      waypoint.actionManager = new BABYLON.ActionManager(this.scene.scene);
      waypoint.actionManager.registerAction(new BABYLON.InterpolateValueAction(BABYLON.ActionManager.OnPickUpTrigger, self.camera.camera, "position", new BABYLON.Vector3(waypoint.position.x, waypoint.position.y + self.waypoints.cameraLift, waypoint.position.z), self.waypoints.transitionTime, null, false));
      waypoint.actionManager.registerAction(new BABYLON.InterpolateValueAction(BABYLON.ActionManager.OnPickUpTrigger, self.camera.camera, "radius", self.camera.radius.near, self.waypoints.transitionTime, null, false));
      waypoint.actionManager.registerAction(new BABYLON.SetValueAction(BABYLON.ActionManager.OnPickUpTrigger, self.camera.camera, "target", new BABYLON.Vector3(waypointData.target.x, waypointData.target.y, waypointData.target.z)));
      waypoint.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickUpTrigger, function(e) {
        window.clearInterval(self.waypoints.triggerDelay);
        self.waypoints.triggerDelay = window.setInterval(function() {
          window.clearInterval(self.waypoints.triggerDelay);
          self.camera.targetNulled = false;
        }, self.waypoints.transitionTime);
      }, null));
      // bind hover actions
      waypoint.actionManager.registerAction(new BABYLON.InterpolateValueAction(BABYLON.ActionManager.OnPointerOutTrigger, waypoint, "scaling", new BABYLON.Vector3(1, 1, 1), 150));
      waypoint.actionManager.registerAction(new BABYLON.InterpolateValueAction(BABYLON.ActionManager.OnPointerOverTrigger, waypoint, "scaling", new BABYLON.Vector3(1.1, 1.1, 1.1), 150));
      // add to global waypoints elements list
      this.waypoints.elements["waypoint_" + waypointData.name] = waypoint;
    }
  }

  GetConfig() {
    if (this.id) {
      this.config = window[this.id + '__content'];
      if (this.config) {
        this.assets.rootUrl = typeof this.config.assetsRootUrl !== "undefined" ? this.config.assetsRootUrl : this.assets.rootUrl;
        this.debug = typeof this.config.debug !== "undefined" ? this.config.debug : false;
        this.fxaa = typeof this.config.fxaa !== "undefined" ? this.config.fxaa : false;
        this.infopoints.data = typeof this.config.infoPoints !== "undefined" ? this.config.infoPoints : [];
        this.shadows = typeof this.config.shadows !== "undefined" ? this.config.shadows : false;
        this.showDebugLayer = typeof this.config.showDebugLayer !== "undefined" ? this.config.showDebugLayer : false;
        this.waypoints.data = typeof this.config.wayPoints !== "undefined" ? this.config.wayPoints : [];
        this.waypoints.texture.animated = typeof this.config.wayPointsAnimated !== "undefined" ? this.config.wayPointsAnimated : true;
        this.windowResizeDelay = typeof this.config.windowResizeDelay !== "undefined" ? this.config.windowResizeDelay : 100;
      }
    }
  }

  InfoPointsZoomShifter() {
    var count, name, offset, position, ref, ref1, ref2, textElement, textOffset, trackSphere;
    // shift infopoints overlay linkOffset with camera zoom
    offset = this.infopoints.overlayDefaultOffset - (1 * this.camera.camera.radius) * this.gui.renderScale * this.dpr;
    count = 0;
    ref = this.infopoints.textElements;
    for (name in ref) {
      textElement = ref[name];
      textElement.linkOffsetX = offset;
      textOffset = this.infopoints.data[count].textOffset ? this.infopoints.data[count].textOffset : 0;
      textElement.linkOffsetX = ((this.infopoints.textElementsDefaultOffset + textOffset) - (1 * this.camera.camera.radius)) * this.infopoints.data[count].textAlignment * this.gui.renderScale * this.dpr * this.resizeFactor;
      count++;
    }
    
    // shift infopoints and trackSpheres positions
    count = 0;
    ref1 = this.infopoints.trackSpheres;
    for (name in ref1) {
      trackSphere = ref1[name];
      if (this.camera.camera.radius > this.infopoints.cameraZoomShiftLevel) {
        position = new BABYLON.Vector3(this.infopoints.data[count].offPosition.x, this.infopoints.data[count].offPosition.y, this.infopoints.data[count].offPosition.z);
      } else {
        position = new BABYLON.Vector3(this.infopoints.data[count].position.x, this.infopoints.data[count].position.y, this.infopoints.data[count].position.z);
      }
      trackSphere.position = position;
      this.infopoints.elements[name].position = position;
      count++;
    }
    ref2 = this.infopoints.textElements;
    // hide textElements of infopoints
    for (name in ref2) {
      textElement = ref2[name];
      textElement.isVisible = this.overlayOpen ? false : this.camera.camera.radius > this.infopoints.cameraZoomShiftLevel;
    }
    if (!this.camera.targetNulled) {
      if (this.camera.camera.radius > this.infopoints.cameraZoomShiftLevel) {
        this.camera.targetNulled = true;
        this.camera.camera.setTarget(new BABYLON.Vector3(0, 0, 0));
      }
    }
  }

  InitEvents() {
    var self;
    self = this;
    this.$el.addEventListener('touchstart', function(e) {
      var i, icon, len, ref, results;
      if (!self.touchDetected) {
        self.touchDetected = true;
        self.$el.classList.add('new-work--touch');
        ref = self.$userHints.querySelectorAll('.new-work__userhints__icon');
        results = [];
        for (i = 0, len = ref.length; i < len; i++) {
          icon = ref[i];
          results.push(icon.classList.add('new-work__userhints__icon--touch'));
        }
        return results;
      }
    });
    window.addEventListener('resize', function(e) {
      self.WindowResize();
    });
  }

  InstantiateMesh(mesh, instanceNumber, positionShift, shadowGen) {
    this.meshes[mesh.id + "_i_" + instanceNumber] = mesh.createInstance(mesh.id + "_i_" + instanceNumber);
    this.meshes[mesh.id + "_i_" + instanceNumber].position = new BABYLON.Vector3(mesh.position.x + positionShift[0], mesh.position.y + positionShift[1], mesh.position.y + positionShift[2]);
    this.meshes[mesh.id + "_i_" + instanceNumber].isPickable = false;
    if (shadowGen) {
      shadowGen.addShadowCaster(this.meshes[mesh.id + "_i_" + instanceNumber]);
    }
    return this.meshes[mesh.id + "_i_" + instanceNumber];
  }

  LoadAssets() {
    // show loading screen
    this.engine.displayLoadingUI();
    // start assets manager loading
    this.assets.manager.load();
    // start load checker
    this.StartAssetsLoadChecker();
  }

  PostSceneOptimizationProcesses() {
    var name, ref, textElement;
    ref = this.infopoints.textElements;
    for (name in ref) {
      textElement = ref[name];
      // set infopoints visible
      this.infopoints.elements[name].isVisible = true;
      if (this.hsl) {
        if (this.engine.getHardwareScalingLevel() !== this.hsl) {
          this.resizeFactor = this.engine.getHardwareScalingLevel() / this.dpr;
          this.resizeFactor = this.resizeFactor < .5 ? .5 : this.resizeFactor;
          // text elements rescale
          textElement.scaleX = this.resizeFactor;
          textElement.scaleY = this.resizeFactor;
        }
      }
      
      // text element set visible
      textElement.isVisible = true;
    }
    if (this.hsl) {
      if (this.engine.getHardwareScalingLevel() !== this.hsl) {
        this.engine.resize();
      }
    }
    this.scene.optimized = true;
  }

  RemoveInfopointOverlay() {
    var ipoElem;
    ipoElem = this.$el.querySelector('.new-work__infopoint-overlay');
    if (ipoElem) {
      ipoElem.remove();
      this.overlayOpen = false;
    }
  }

  RunScene() {
    var self;
    self = this;
    if (this.debug) {
      console.log("RUN SCENE");
    }
    this.engine.runRenderLoop(function() {
      self.scene.scene.render();
      if (self.scene.optimized) {
        self.InfoPointsZoomShifter();
      }
    });
  }

  ShowInfopointOverlay(number) {
    var infopointData, ipoElem, self;
    self = this;
    if (this.debug) {
      console.log("SHOW INFOPOINT OVERLAY", number);
    }
    this.RemoveInfopointOverlay();
    infopointData = this.infopoints.data[number];
    if (infopointData) {
      ipoElem = document.createElement('div');
      ipoElem.classList.add('new-work__infopoint-overlay');
      ipoElem.innerHTML = '<div class="new-work__infopoint-overlay__header"><h2>' + infopointData.text + '</h2><div class="new-work__infopoint-overlay__header__closer"></div></div><div class="new-work__infopoint-overlay__text">' + infopointData.overlay.text + '</div><a class="new-work__infopoint-overlay__button" href="' + infopointData.overlay.button.url + '" target="' + infopointData.overlay.button.target + '">' + infopointData.overlay.button.text + '</a></div>';
      if (self.debug) {
        ipoElem.querySelector('.new-work__infopoint-overlay__button').addEventListener('click', function(e) {
          console.log("BUTTON CLICK", infopointData.overlay.button.url);
        });
      }
      ipoElem.querySelector('.new-work__infopoint-overlay__header__closer').addEventListener('click', function(e) {
        return self.RemoveInfopointOverlay();
      });
      this.$el.appendChild(ipoElem);
    }
  }

  StartAssetsLoadChecker() {
    var self;
    self = this;
    if (!this.assets.loadChecker) {
      this.assets.loadChecker = window.setInterval(function() {
        var deskGroup2, deskGroup3, kitchenSeat;
        if (self.debug) {
          console.log("CHECK FOR LOADING ASSETS");
        }
        if (self.assets.openTasks < 1) {
          if (self.debug) {
            console.log("ALL ASSETS LOADED");
          }
          window.clearInterval(self.assets.loadChecker);
          self.assets.loadChecker = void 0;
          self.engine.hideLoadingUI();
          // Instances
          self.InstantiateMesh(self.meshes["HighChair"], 1, [0, 0, 1.2], self.shadowGens["#dLight-01"]);
          self.InstantiateMesh(self.meshes["HighChair"], 2, [0, 0, -1.2], self.shadowGens["#dLight-01"]);
          kitchenSeat = self.InstantiateMesh(self.meshes["KitchenSeat"], 1, [.5, 0, -12.6], self.shadowGens["#dLight-01"]);
          kitchenSeat.rotation.z = Math.PI * (270 / 180);
          self.InstantiateMesh(self.meshes["DeskGroup"], 1, [-3, 0, 0], self.shadowGens["#dLight-01"]);
          deskGroup2 = self.InstantiateMesh(self.meshes["DeskGroup"], 2, [4.8, 0, 11.1], self.shadowGens["#dLight-01"]);
          deskGroup2.rotation.z = Math.PI;
          deskGroup3 = self.InstantiateMesh(self.meshes["DeskGroup"], 3, [7.8, 0, 11.1], self.shadowGens["#dLight-01"]);
          deskGroup3.rotation.z = Math.PI;
          self.InstantiateMesh(self.meshes["KitchenTable"], 1, [-11, .27, 10.7], self.shadowGens["#dLight-01"]);
        }
      }, this.assets.loadCheckerInterval);
    }
  }

  WindowResize() {
    var self;
    self = this;
    window.clearInterval(this.windowResizeInterval);
    this.windowResizeInterval = window.setInterval(function() {
      window.clearInterval(self.windowResizeInterval);
      self.engine.resize();
    }, this.windowResizeDelay);
  }

};

BindNewWorks = function() {
  return document.querySelectorAll('.new-work:not(.new-work--binded)').forEach(function(el) {
    new NewWork(el);
  });
};

BindNewWorks();