import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

on('click', '.accordion__summary', (event) => {
  const { currentTarget: $toggle } = event;
  const $accordion = $toggle.closest('.js-accordion') as HTMLDetailsElement;

  invisibleFocus($toggle);

  const accordions = document.querySelectorAll(
    '.js-accordion',
  ) as NodeListOf<HTMLDetailsElement>;

  // Close all other accordions
  accordions.forEach((otherAccordion: HTMLDetailsElement) => {
    // Callback function to handle each accordion
    if (otherAccordion !== $accordion && otherAccordion.open) {
      otherAccordion.open = false; // Statement within the callback
    }
  });
});
