import createSlider from '../../../javascripts/utils/createSlider';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

const create = ($ribbonSlider: HTMLDivElement): CallableFunction => {
  const $cards = $ribbonSlider.querySelector<HTMLDivElement>(
    '.ribbon-slider__cards',
  );

  if (!$cards) {
    throw new Error('Cards are missing!');
  }

  const slider = createSlider($cards, '.ribbon-slider__card');

  const prev = ({ currentTarget }: MouseEvent) => {
    if (currentTarget instanceof HTMLElement) invisibleFocus(currentTarget);
    slider.move('prev');
  };

  const next = ({ currentTarget }: MouseEvent) => {
    if (currentTarget instanceof HTMLElement) invisibleFocus(currentTarget);
    slider.move('next');
  };

  const $prevButton = $ribbonSlider.querySelector<HTMLButtonElement>(
    '.ribbon-slider__navigation-button--prev',
  );

  $prevButton?.addEventListener('click', prev);

  const $nextButton = $ribbonSlider.querySelector<HTMLButtonElement>(
    '.ribbon-slider__navigation-button--next',
  );

  $nextButton?.addEventListener('click', next);

  return () => {
    slider.destroy();
    $prevButton?.removeEventListener('click', prev);
    $nextButton?.removeEventListener('click', prev);
  };
};

export default create;
