import createTimer from '../../../javascripts/utils/createTimer';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import whenReady from '../../../javascripts/utils/whenReady';

export interface Pager {
  start(): void;
  stop(): void;
  toggle(): void;
  pause(): void;
  resume(): void;
  paused(): boolean;
  setCurrentPage(value: number, relative: boolean): void;
}

type PagerInitEventDetail = {
  pager: Pager;
};

type PagerNewCurrentPageEventDetail = {
  oldPage: number;
  currentPage: number;
  manual: boolean;
};

export type PagerInitEvent = CustomEvent<PagerInitEventDetail>;
export type PagerNewCurrentPageEvent =
  CustomEvent<PagerNewCurrentPageEventDetail>;

whenReady().then(() => {
  const $$pager = document.querySelectorAll<HTMLDivElement>('.pager');

  $$pager.forEach(($pager) => {
    let currentPage = 0;

    const $$pageButton = $pager.querySelectorAll<HTMLButtonElement>(
      '.pager__page-button',
    );

    const $pauseButton =
      $pager.querySelector<HTMLButtonElement>('.pager__autoplay');

    const setCurrentPage = (value: number, relative = true, manual = false) => {
      const oldPage = currentPage;

      if (!relative) {
        currentPage =
          value >= 0 && value < $$pageButton.length ? value : currentPage;
      } else if (value === -1) {
        currentPage =
          currentPage === 0 ? $$pageButton.length - 1 : currentPage - 1;
      } else if (value === 1) {
        currentPage =
          currentPage === $$pageButton.length - 1 ? 0 : currentPage + 1;
      }

      // Remove state from old current page
      $$pageButton[oldPage]?.setAttribute('aria-current', 'false');
      $$pageButton[oldPage]?.setAttribute('data-fadeout', 'true');
      $$pageButton[oldPage]?.removeAttribute('disabled');
      $$pageButton[oldPage]?.style.setProperty('--progress', '0%');

      // Add state to new current page
      $$pageButton[currentPage]?.setAttribute('aria-current', 'true');
      $$pageButton[currentPage]?.setAttribute('disabled', 'disabled');
      $$pageButton[currentPage]?.style.setProperty('--progress', '100%');

      // Dispatch event
      $pager.dispatchEvent(
        new CustomEvent<PagerNewCurrentPageEventDetail>('pager:page', {
          bubbles: true,
          detail: {
            oldPage,
            currentPage,
            manual,
          },
        }),
      );
    };

    const timer = createTimer(() => setCurrentPage(1), 5000, true);

    timer.onTick((progress) => {
      $$pageButton[currentPage]?.style.setProperty(
        '--progress',
        `${(progress * 100).toPrecision(2).toString()}%`,
      );
    });

    const paused = () => timer.paused();

    const start = () => {
      if (timer.stopped()) {
        $pauseButton?.setAttribute('aria-pressed', 'false');
        timer.start();
      }
    };

    const pause = () => {
      if (!timer.paused() && !timer.stopped()) {
        timer.pause();
      }
    };

    const resume = () => {
      if (timer.paused() && !timer.stopped()) {
        timer.resume();
      }
    };

    const stop = () => {
      timer.stop();
      $$pageButton[currentPage]?.style.setProperty('--progress', '0%');
      $pauseButton?.setAttribute('aria-pressed', 'true');
    };

    const toggle = () => {
      if (timer.stopped()) {
        start();
      } else {
        stop();
      }
    };

    $$pageButton.forEach(($pageButton, index) => {
      $pageButton.addEventListener('click', () => {
        invisibleFocus($pageButton);
        stop();
        setCurrentPage(index, false, true);
      });

      $pageButton.addEventListener('animationend', () => {
        $pageButton.removeAttribute('data-fadeout');
      });
    });

    $pauseButton?.addEventListener('click', () => {
      invisibleFocus($pauseButton);
      toggle();
    });

    const pagerInitEvent = new CustomEvent<PagerInitEventDetail>('pager:init', {
      bubbles: true,
      detail: {
        pager: {
          start,
          stop,
          pause,
          resume,
          toggle,
          setCurrentPage,
          paused,
        },
      },
    });

    $pager.dispatchEvent(pagerInitEvent);
  });
});
